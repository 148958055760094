import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';

import * as fromApp from '../../store/app.reducers';

import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
    UserTokenCookieName: string = 'userToken';
    UserToken: string = this.cookieService.get(this.UserTokenCookieName);
    userIsLoggedIn: boolean = this.cookieService.check(this.UserTokenCookieName);

    constructor(
        private httpClient: HttpClient,
        private store: Store<fromApp.AppState>,
        private cookieService: CookieService) {
        if (this.cookieService.check('userToken')) {
            this.UserToken = String(this.cookieService.get('userToken'));
        } else {
            this.UserToken = 'undefined';
        }
    }

    getUser(userToken?: string) {
        userToken = userToken || this.UserToken;
        return this.httpClient.get(
            environment.API_URL + '/user/' + userToken
        );
    }

    isAuthenticated() {
        if (this.UserToken != 'undefined') {
            return true;
        }
        return false;
    }

    TryLogin(loginData: { username: string, password: string, rememberMe?: boolean }) {
        return this.httpClient.post(
            environment.API_URL + '/user/login',
            JSON.stringify({ email: loginData.username, password: loginData.password })
        );
    }

    AddUser(userData: any) {
        return this.httpClient.post(
            environment.API_URL + '/user/',
            userData
        );
    }

    UpdateUser(user: User): Observable<User> {
        return this.httpClient.put<User>(
            environment.API_URL + '/user/' + user.token,
            user
        );
    }

    DeleteUser(userToken: string, userLogin: { email: string, password: string }) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: userLogin
        };

        return this.httpClient.delete(
            environment.API_URL + '/user/' + userToken,
            httpOptions
        )
    }

    ForgotPassword(username: string) {
        return this.httpClient.post<String>(
            environment.API_URL + '/user/password/forgot',
            null,
            {
                params: {
                    username: username
                }
            }
        )
    }
}