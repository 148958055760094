import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';

import * as fromApp from '../../store/app.reducers';
import * as fromUser from '../../modules/user/store/user.reducers';
import * as userActions from '../../modules/user/store/user.actions';
import * as wishlistActions from '../../modules/backoffice/wishlist/store/wishlist.actions';

import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { WishlistService } from '../services/wishlist.service';
import { CoreService } from '../services/core.service';

@Injectable()
export class AppResolve implements Resolve<any> {

    constructor(
        private userService: UserService,
        private store: Store<fromApp.AppState>,
        private coreService: CoreService
    ) { }

    getFromStoreOrAPI(): Observable<any> {
        return this.store.pipe(
            select(state => {
                return state;
            }),
            tap((state) => {
                if (state.auth == null && this.userService.userIsLoggedIn) {
                    this.store.dispatch(userActions.fetchUser());
                    this.store.dispatch(wishlistActions.fetchWishlist());
                }
            }),
            filter((state: fromApp.AppState) => {
                if (this.userService.userIsLoggedIn) {
                    return ((state.auth) != null) && ((state.wishlists != null));
                }
                
                return true;
            }),
            take(1)
        );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.getFromStoreOrAPI().pipe(
            switchMap(() => {
                this.coreService.AppIsInitializing(false);
                return of(true)
            }),
            catchError(() => {
                this.coreService.AppIsInitializing(false);
                return of(false);
            })
        );
    }

}