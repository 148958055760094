import { createAction, props } from '@ngrx/store';

import { Wishlist } from '../../../../shared/models/wishlist.model';
import { Wish } from '../../../../shared/models/wish.model';

export const setWishlists = createAction('[Wishlists Component] Set wishlist', props<{wishlists: Wishlist[]}>());
export const addWishlist = createAction('[Wishlists Component] Add wishlist', props<{wishlist: Wishlist}>());
export const getWishlist = createAction('[Wishlists Component] Get wishlist', props<{ token: string }>());
export const updateWishlist = createAction('[Wishlists Component] Update wishlist', props<{ wishlist: Wishlist}>());
export const removeWishlist = createAction('[Wishlists Component] Remove wishlist', props<{token: string}>());
export const fetchWishlist = createAction('[Wishlists Component] Fetch wishlist');

export const addWish = createAction('[Wishlists Component] Add wish', props<{ wish: Wish }>());
export const updateWish = createAction('[Wishlists Component] Update wish', props<{ wish: Wish }>());
export const removeWish = createAction('[Wishlists Component] Remove wish', props<{ wishToken: string }>());