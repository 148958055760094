import { Wishlist } from '../../../../shared/models/wishlist.model';

import { createReducer, on } from '@ngrx/store';

import { setWishlists, updateWish, addWish, addWishlist, updateWishlist, removeWishlist, removeWish } from './wishlist.actions';
import { Wish } from 'src/app/shared/models/wish.model';
import { state } from '@angular/animations';

export interface State extends Array<Wishlist> { }

export const initialState: Wishlist[] = null;

const _wishlistReducer = createReducer(initialState,
    on(setWishlists, (state, { wishlists }) => wishlists),
    on(addWishlist, (state, { wishlist }) => [wishlist, ...state]),
    on(updateWishlist, (state, { wishlist }) => {
        // Get current wishlist.
        let currentWishlist = state.find(x => x.token == wishlist.token);

        // Create merged wishlist.
        let mergedWishlist: Wishlist = {
            ...wishlist,
            wishes: currentWishlist.wishes,
            event: currentWishlist.event
        }

        // Replace wishlist.
        state.splice(state.findIndex(x => x.token == wishlist.token), 1, mergedWishlist);

        // Return updated state.
        return [...state];
    }),
    on(removeWishlist, (state, { token }) => {
        let wishlist: Wishlist = state.find(x => x.token == token);

        // Remove wish.
        state.splice(state.indexOf(wishlist), 1);

        // Return state.
        return state;
    }),
    on(addWish, (state: Wishlist[], { wish }) => {
        let wishlist = state.find(x => x.token == wish.wishlist);

        if (wishlist.wishes) {
            // Prepend to wishes property.
            wishlist.wishes = [wish, ...wishlist.wishes];
        } else {
            // Set wishes property.
            wishlist.wishes = [wish];
        }

        return state;
    }),
    on(updateWish, (state, { wish }) => {
        const wishes = [ ...state.find(x => x.token == wish.wishlist).wishes ];
        const oldWish = state.find(x => x.token == wish.wishlist).wishes.find(x => x.token == wish.token);

        wishes.splice(wishes.indexOf(oldWish), 1, wish)

        // Return state.
        return [
            ...state.filter(x => x.token != wish.wishlist),
            {
                ...state.find(x => x.token == wish.wishlist),
                wishes: wishes
            }
        ];
    }),
    on(removeWish, (state, { wishToken }) => {
        let wishlist: Wishlist = state.find(x => x.wishes ? x.wishes.some(y => y.token == wishToken) : null);
        if (!wishlist) {
            return;
        }
        let wish: Wish = wishlist.wishes.find(x => x.token == wishToken);

        // Remove wish.
        state[state.indexOf(wishlist)].wishes.splice(wishlist.wishes.indexOf(wish), 1);

        // Return state.
        return state;
    }),
);

export function wishlistReducer(state, action) {
    return _wishlistReducer(state, action);
}