import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map } from 'rxjs/operators';
import { Wishlist } from '../../../../shared/models/wishlist.model';

import * as wishlistActions from './wishlist.actions';
import { WishlistService } from '../../../../shared/services/wishlist.service';

@Injectable()
export class WishlistEffects {

    constructor(
        private actions$: Actions,
        private wishlistService: WishlistService
    ) { }

    FetchWishlists$ = createEffect(() =>
        this.actions$.pipe(
            ofType(wishlistActions.fetchWishlist),
            switchMap(() => {
                return this.wishlistService.GetWishlists().pipe(
                    map((wishlists: Wishlist[]) => {
                        if (wishlists == null) {
                            wishlists = [];
                        }
                        
                        return wishlistActions.setWishlists({ wishlists });
                    })
                )
            })
        )
    );
}