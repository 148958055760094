import * as fromApp from './store/app.reducers';
import * as fromUser from './modules/user/store/user.reducers';
import * as userActions from './modules/user/store/user.actions';

import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { of, Observable } from 'rxjs';
import { map, switchMap, catchError, filter, take, tap } from 'rxjs/operators';

@Injectable()
export class AppGuard implements CanActivate {

    constructor(
        private store: Store<fromApp.AppState>,
        private router: Router,
        private cookieService: CookieService) { }

    // wrapping the logic so we can .switchMap() it
    getFromStoreOrAPI(): Observable<any> {
        return this.store.select('auth')
            .pipe(
                tap((user: fromUser.State) => {
                    if (!user) {
                        this.store.dispatch(userActions.fetchUser());
                    }
                }),
                filter((user: fromUser.State) => { return user == null }),
                take(1)
            );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        // return this.getFromStoreOrAPI().pipe(
        //     switchMap(() => of(true)),
        //     catchError(() => of(this.router.navigateByUrl('/login')))
        //     // catchError(() => this.router.navigateByUrl('/login'))
        // );


        if (this.cookieService.check('userToken')) {
            return true;
        } else {
            this.router.navigate(['/login']);
            return false;
        }
    }
}