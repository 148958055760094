import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from './shared/services/user.service';
import { Store } from '@ngrx/store';

import * as fromApp from './store/app.reducers';
import * as CoreActions from './modules/core/store/core.actions';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AppProvider {

    constructor(
        private userService: UserService,
        private store: Store<fromApp.AppState>,
        private cookieService: CookieService) {
    }

    initializeAppData() {
        // Check cookie acceptance
        if (this.cookieService.get('ac') == 'true') {
            this.store.dispatch(new CoreActions.CookiesAccepted());
        }

        let userToken = this.userService.UserToken;

        if (userToken != 'undefined') {
            return new Promise((resolve, reject) => {
            resolve();
                // this.httpClient.get(
                //     environment.API_URL + '/user/' + userToken                    
                // ).subscribe(
                //     (user: User) => {
                //         this.store.dispatch(new userActions.InitializeUser(user));
                //         this.store.dispatch(new userActions.SetToken(userToken));
                //         resolve();
                //     },
                //     (error) => {
                //         reject(error);
                //     }
                // );
            });
        }
    }

    
}