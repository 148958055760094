import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { tap, filter, take } from 'rxjs/operators';

import * as fromApp from '../../store/app.reducers';
import * as userActions from '../../modules/user/store/user.actions';
import * as wishlistActions from '../../modules/backoffice/wishlist/store/wishlist.actions';

import { CoreService } from '../services/core.service';
import { UserService } from '../services/user.service';

@Injectable()
export class OverlayResolve implements Resolve<any> {

    constructor(
        private store: Store<fromApp.AppState>,
        private userService: UserService,
        private coreService: CoreService
    ) { }

    getFromStoreOrAPI(): Observable<any> {
        return this.store.pipe(
            select(state => {
                return state;
            }),
            tap((state) => {
                if (state.auth == null && this.userService.userIsLoggedIn) {
                    this.store.dispatch(userActions.fetchUser());
                    this.store.dispatch(wishlistActions.fetchWishlist());
                }
            }),
            filter((state: fromApp.AppState) => {
                if (this.userService.userIsLoggedIn) {
                    return ((state.auth) != null) && ((state.wishlists != null));
                }

                return true;
            }),
            take(1)
        );
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // Remove 'loading' overlay.
        this.coreService.AppIsInitializing(false);
        
        // #region Begin lazyload of user data.
        let _fetchFromAPI = () => {
            let storeFetch$;
    
            let _unsubscribeFromstoreFetch = () => {
                if (storeFetch$ && !storeFetch$.closed) {
                    storeFetch$.unsubscribe();
                }
            }

            storeFetch$ = this.getFromStoreOrAPI().subscribe(
                () => {
                    // When done, unsubscribe.
                    _unsubscribeFromstoreFetch();
                },
                () => {
                    // On error, unsubscribe.
                    _unsubscribeFromstoreFetch();
                }
            );
        }

        let state$;
        
        let _unsubscribeFromState = () => {
            if (state$) {
                state$.unsubscribe();
            }
        }

        state$ = this.store.select((state) => {
            return state;
        }).subscribe(
            ((state) => {
                // Check if data needs to be fetched.
                if (state.auth == null || state.wishlists == null) {
                    _fetchFromAPI();
                }

                // Unsubscribe from NgRx store.
                _unsubscribeFromState();
            }),
            () => {
                // Unsubscribe from NgRx store.
                _unsubscribeFromState();
            }
        );
        //#endregion

        // Return 'true', since fetching is an async process.
        return of(true);
    }

}