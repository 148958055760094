import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { CookieService } from 'ngx-cookie-service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

// #region Import modules.
import { AppRoutingModule } from './app-routing.module';
// #endregion

// #region Import components.
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
// #endregion

import { AppComponent } from './app.component';
import { LandingpageComponent } from './modules/core/landingpage/landingpage.component';
import { PageNotFoundComponent } from './modules/error-pages/page-not-found/page-not-found.component';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './modules/user/store/user.effects';
import { UserService } from './shared/services/user.service';
import { AppGuard } from './app-guard.service';
import { AppProvider } from './app.provider';
import { CustomModalService } from './shared/services/modal.service';
import { FileManagerService } from './shared/services/filemanager.service';
import { AppResolve } from './shared/resolvers/user.resolve';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';

import * as fromUser from './modules/user/store/user.reducers';
import * as fromCore from './modules/core/store/core.reducers';
import * as fromWishlist from './modules/backoffice/wishlist/store/wishlist.reducers';
import * as fromFrontendWishlist from './modules/wishlist/store/wishlist.reducers';

import { WishlistService } from './shared/services/wishlist.service';
import { WishlistEffects } from './modules/backoffice/wishlist/store/wishlist.effects';
import { CoreService } from './shared/services/core.service';
import { SharedModule } from './shared/shared.module';
import { OverlayResolve } from './shared/resolvers/overlay.resolve';
import { AuthService } from './modules/auth/_services/auth.service';
import { WishService } from './shared/services/wish.service';
import { WishEditorService } from './modules/backoffice/editor/_services/wish-editor.service';
import { WishlistBackofficeGuard } from './shared/guards/wishlist-backoffice-guard.service';
import { SEOService } from './shared/services/seo.service';
import { SignupRequiredGuard } from './shared/guards/signup-required.guard';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    LandingpageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    StoreModule.forRoot({
      auth: fromUser.userReducer,
      wishlists: fromWishlist.wishlistReducer,
      frontendWishlist: fromFrontendWishlist.wishlistReducer,
      cookiesAccepted: fromCore.coreReducer
    }),
    SweetAlert2Module.forRoot(),
    EffectsModule.forRoot([UserEffects, WishlistEffects]),
  ],
  providers: [
    UserService,
    CookieService,
    AuthService,
    AppGuard,
    SignupRequiredGuard,
    WishlistBackofficeGuard,
    AppProvider,
    CustomModalService,
    FileManagerService,
    AppResolve,
    OverlayResolve,
    CoreService,
    WishService,
    WishlistService,
    WishEditorService,
    SEOService,
    { provide: APP_INITIALIZER, useFactory: AppProviderFactory, deps: [AppProvider], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function AppProviderFactory(provider: AppProvider) {
  return () => provider.initializeAppData();
}