import { createAction, props } from '@ngrx/store';

import { User } from '../../../shared/models/user.model';

export const tryLogin = createAction('[Users Component] Try login', props<{username: string, password: string, rememberMe: boolean}>());
export const signup = createAction('[Users Component] Signup');
export const initializeUser = createAction('[Users Component] Initialize user', props<{user: User}>());
export const login = createAction('[Users Component] Login', props<{user: User}>());
export const loginError = createAction('[Users Component] Login error');
export const logout = createAction('[Users Component] Logout');
export const fetchUser = createAction('[Users Component] Fetch user');