import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularCropperjsModule } from 'angular-cropperjs';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { FirstNamePipe } from './pipes/first-name.pipe';
import { ReadMoreComponent } from './components/read-more/read-more.component';


// #region Import directives
import { CustomModalDirective } from './directives/custom-modal.component';
// #endregion

@NgModule({
  declarations: [
    CustomModalDirective,
    FirstNamePipe,
    ReadMoreComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgbModule,
    HttpClientModule,
    AngularCropperjsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SweetAlert2Module,
    NgbModule,
    HttpClientModule,
    CustomModalDirective,
    AngularCropperjsModule,
    FirstNamePipe,
    ReadMoreComponent,
  ],
  providers: [
    FirstNamePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ]
})
export class SharedModule { }
