import { Injectable, OnInit } from "@angular/core";

import { Wish } from 'src/app/shared/models/wish.model';
import { CustomModalService } from 'src/app/shared/services/modal.service';
import { Subject } from 'rxjs';

import { WishService } from 'src/app/shared/services/wish.service';

@Injectable()
export class WishEditorService implements OnInit {
    private editorData: Subject<{ wish: Wish; wishlistToken: string; mode: 0 | 1; }> = new Subject<{ wish: Wish; wishlistToken: string; mode: 0 | 1; }>();
    private editorID: string = 'wish-editor';

    constructor(
        private modalService: CustomModalService,
        private wishService: WishService
    ) { }

    ngOnInit() {
       
    }

    GetEditorData() {
        return this.editorData.asObservable();
    }

    AddWish(wishlistToken: string, wish: Wish = undefined, mode: 0 | 1 = 0): void {
        this.editorData.next({ wish: wish, wishlistToken: wishlistToken, mode: 0 });

        // Open editor.
        this.modalService.open(this.editorID);
    }

    EditWish(wishToken: string): void {
        // Fetch wishlist.
        let wish$;

        wish$ = this.wishService.GetWish(wishToken).subscribe(
            (wish) => {
                // Patch values of wish to form.
                this.editorData.next({ wish: wish, wishlistToken: undefined, mode: 1 });
                
                // Open editor.
                this.modalService.open(this.editorID);
            }
        );
        
        // Unsubscribe from 'GetWish' method, since it may return an observable from NgRx.
        if (wish$ && !wish$.closed) {
            wish$.unsubscribe();
        }
    }
}