import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppResolve } from './shared/resolvers/user.resolve';
import { AppGuard } from './app-guard.service';
import { LandingpageComponent } from './modules/core/landingpage/landingpage.component';
import { PageNotFoundComponent } from './modules/error-pages/page-not-found/page-not-found.component';
import { OverlayResolve } from './shared/resolvers/overlay.resolve';
import { WishlistBackofficeGuard } from './shared/guards/wishlist-backoffice-guard.service';
import { SignupRequiredGuard } from './shared/guards/signup-required.guard';


const appRoutes: Routes = [
  {
    path: '', children: [
      
      // #region Load 'landingpage' component.
      { path: '', pathMatch: 'full', component: LandingpageComponent, resolve: [ OverlayResolve ] },
      // #endregion
      
      // #region LazyLoad 'auth' modules.
      { path: 'login', resolve: [ OverlayResolve ], loadChildren: () => import('./modules/auth/login/login.module').then(m => m.LoginModule) },
      { path: 'sign-up', resolve: [ OverlayResolve ], loadChildren: () => import('./modules/auth/signup/signup.module').then(m => m.SignupModule) },
      // #endregion

      // #region LazyLoad 'my-wishlists' modules.
      {
        path: 'my-wishlists', resolve: { AppResolve }, children: [
          { path: '', pathMatch: 'full', canActivate: [ SignupRequiredGuard ], loadChildren: () => import('./modules/backoffice/overview/overview.module').then(m => m.OverviewModule) },
          { path: 'wishlist/:wishlistToken', canActivate: [ WishlistBackofficeGuard ], loadChildren: () => import('./modules/backoffice/wishlist/wishlist.module').then(m => m.WishlistModule) },

          // Default fallback/'Page not found' component.
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      // #endregion

      // #region LazyLoad 'profile' modules.
      {
        path: 'profile', resolve: { AppResolve }, canActivate: [AppGuard], children: [
          { path: '', pathMatch: 'full', loadChildren: () => import('./modules/backoffice/profile/profile.module').then(m => m.ProfileModule) },

          // Default fallback/'Page not found' component.
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      // #endregion

      // #region LazyLoad 'wishlists' modules.
      {
        path: 'wishlist', resolve: { AppResolve }, children: [
          { path: '', loadChildren: () => import('./modules/wishlist/wishlist.module').then(m => m.WishlistModule) },

          // Default fallback/'Page not found' component.
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      // #endregion

      // #region LazyLoad 'about' modules.
      {
        path: 'about', resolve: { OverlayResolve }, children: [
          { path: '', loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule) },

          // Default fallback/'Page not found' component.
          { path: '**', component: PageNotFoundComponent }
        ]
      },
      // #endregion
    ]
  },
  { path: '**', component: PageNotFoundComponent, resolve: [ OverlayResolve ], data: { title: 'Siden blev ikke fundet - Wisher.dk', description: 'Den side eller resource du efterspurgte, blev ikke fundet.' } },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }