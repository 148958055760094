import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import * as fromApp from './../store/app.reducers';
import * as CoreActions from '../modules/core/store/core.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  appState$;
  currentDate: Date = new Date();

  constructor(
    private store: Store<fromApp.AppState>,
    private cookieService: CookieService
  ) { }

  ngOnInit() {
    // Initialize store
    this.appState$ = this.store.select('cookiesAccepted');
  }

  onAcceptCookies() {
    // Set 'ac' cookie, to expire in 10 years
    this.cookieService.set('ac', 'true', new Date(new Date().setDate(new Date().getDate() + (365 * 5))));
    this.store.dispatch(new CoreActions.CookiesAccepted());
  }

}
