<nav class="custom-nav position-fixed w-100 shadow-sm">
    <div class="bg-white">
        <div class="container mr-auto ml-auto d-flex justify-content-between align-items-center bg-white pr-3 pl-3">
            <button type="menu" class="custom-nav-mobile-menu-button" aria-label="menu" type="menu" (click)="isCollapsed = !isCollapsed">
                <i class="fas fa-bars" [ngClass]="{ 'invisible': !isCollapsed }"></i>
            </button>
            <h1 class="m-0">
                <a routerLink="/" class="custom-nav-logo text-gold mr-4">Wisher</a>
            </h1>
            <div></div>
        </div>
    </div>
    <div class="custom-nav__foldout" [ngClass]="{ 'active': !isCollapsed }" (click)="isCollapsed = true">
        <div class="custom-nav__foldout-container bg-white" [ngClass]="{ 'active': !isCollapsed }" (click)="$event.stopPropagation()">
            <header class="custom-nav__foldout-container-header border-bottom pt-3 pr-0 pb-3 pl-3 d-flex align-items-center justify-content-between overflow-hiddem">
                <h1 class="h6 mb-0 text-dark text-uppercase">Menu</h1>
                <i class="fas fa-times pt-5 pr-4 pb-5 pl-5 custom-nav__foldout-container-header__close-icon" (click)="isCollapsed = !isCollapsed"></i>
            </header>
            <div class="custom-nav__foldout-container-body pt-1 pr-3 pb-3 pl-3 d-flex flex-column justify-content-between overflow-auto">
                <ul (click)="isCollapsed = true" class="list-group list-group-flush">
                    <li class="list-group-item pr-0 pl-0">
                        <a routerLink="/" class="text-dark d-block">Forside</a>
                    </li>
                    <li class="list-group-item pr-0 pl-0">
                        <a routerLink="/my-wishlists" class="text-dark d-block">Mine ønskelister</a>
                    </li>
                    <li class="list-group-item pr-0 pl-0">
                        <a routerLink="/profile" class="text-dark d-block">Min profil</a>
                    </li>
                    <li class="list-group-item pr-0 pl-0">
                        <a routerLink="/about" class="text-dark d-block">Om Wisher</a>
                    </li>
                    <li class="list-group-item pr-0 pl-0">
                        <a routerLink="/about/contact" class="text-dark d-block">Kontakt Wisher</a>
                    </li>
                </ul>
                <div class="mt-3">
                    <ng-container *ngIf="!!(isAuthenticated | async); else logged_out_section">
                        <button type="button" class="btn btn-block btn-blue" [swal]="confirmLogoutSwal">Log ud</button>
                        <swal #confirmLogoutSwal (confirm)="onLogout()" title="Log ud" text="Er du sikker på, at du vil logge ud?" type="question" [buttonsStyling]="false" confirmButtonText="Log ud" confirmButtonClass="btn btn-gold mr-3 text-white" showCancelButton="true" cancelButtonText="Annulér" cancelButtonClass="btn btn-primary">
                        </swal>
                    </ng-container>
                    <ng-template #logged_out_section>
                        <a routerLink="/sign-up" class="btn btn-block btn-gold text-white">Opret bruger</a>
                        <a routerLink="/login" class="btn btn-block btn-blue">Log ind</a>
                    </ng-template>
                    <p class="custom-nav__foldout-container-body-footer-text text-muted text-center mt-4 mr-auto mb-2 ml-auto"><i>Lykke defineres ikke, af det vi får, men af det, vi giver. <span class="text-dark ml-2">- Ben Carson</span></i></p>
                </div>
            </div>
        </div>
    </div>
</nav>