import { createReducer, on } from '@ngrx/store';

import { setWishlist, updateWish, setPageScrollPosition } from './wishlist.actions';
import { Wishlist } from 'src/app/shared/models/wishlist.model';

export interface State {
    wishlist: Wishlist,
    pageScrollLocation: {
        x: number,
        y: number
    }
}

export const initialState: State = {
    wishlist: null,
    pageScrollLocation: null
};

const _wishlistReducer = createReducer(initialState,
    on(setWishlist, (state, { wishlist }) => { return { ...state, wishlist: wishlist }; }),
    on(updateWish, (state, { token, wish }) => { 
        let _wish = state.wishlist.wishes.find(x => x.token == token);

        // Set wish if found.
        if (_wish) {
            _wish = wish;
        }
        
        // Return modyfied state.
        return state;
     }),
    on(setPageScrollPosition, (state, { x, y }) => { return { ...state, pageScrollLocation: { x, y } } }),
);

export function wishlistReducer(state, action) {
    return _wishlistReducer(state, action);
}