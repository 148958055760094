import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let src = '/assets/images/partials/landingpage/background/bg.jpg';

    const object: HTMLDivElement = document.querySelector('.landingpage__header-bg.bg-async:not(.loaded)');


    // Start loading image
    const img = new Image();
    img.src = src;
    // Once image is loaded replace the src of the HTML element
    img.onload = () => {
      object.classList.add('loaded');
      object.style.backgroundImage = `linear-gradient(to top left, #00000078, #00000078), url(${src})`;
    };
  }

}
