import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Wishlist } from '../models/wishlist.model';
import { Store, select } from '@ngrx/store';

import * as fromApp from '../../store/app.reducers'
import { map } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class WishlistService implements OnDestroy {
    auth;
    authSub;

    constructor(
        private httpClient: HttpClient,
        private store: Store<fromApp.AppState>,
        private userService: UserService
    ) {
        // Initialize NgRx state.
        this.authSub = store.select(state => state.auth)
            .subscribe((auth) => {
                this.auth = auth;
            });
    }

    ngOnDestroy() {
        // Unsubscribe from NgRx subscription.
        this.authSub.unsubscribe();
    }

    GetWishlist(wishlistToken: string) {
        // Search for wishlist in NgRx store.
        this.store.select(state => state.wishlists)
            .pipe(
                map((wishlists: Wishlist[]) => {
                    if (wishlists == null) {
                        return null;
                    }

                    let selectedWishlist = wishlists.find(x => x.token == wishlistToken);

                    return selectedWishlist;
                })
            );

        // Default fallback.
        return this.httpClient.get<Wishlist>(
            environment.API_URL + '/wishlist/' + wishlistToken
        );
    }

    GetWishlists(): Observable<Wishlist[]> {
        let result: Wishlist[] = null;
        this.store.select(state => state.wishlists)
            .pipe(
                map((wishlists: Wishlist[]) => {
                    if (wishlists != null) {
                        result = wishlists;
                    }
                })
            );

        if (result != null) {
            return of(result);
        }

        let userToken = (this.userService.UserToken == 'undefined' ? (this.auth ? this.auth.token : '') : this.userService.UserToken);

        return this.httpClient.get<Wishlist[]>(
            environment.API_URL + '/wishlists/' + userToken
        );

    }

    AddWishlist(wishlist: Wishlist) {
        return this.httpClient.post<Wishlist>(
            environment.API_URL + '/wishlist',
            wishlist
        );
    }

    UpdateWishlist(wishlist: Wishlist) {
        return this.httpClient.put<Wishlist>(
            environment.API_URL + '/wishlist/' + wishlist.token + '/' + this.auth.token,
            wishlist
        );
    }

    DeleteWishlist(wishlistToken: string) {
        return this.httpClient.delete(
            environment.API_URL + '/wishlist/' + wishlistToken + '/' + this.userService.UserToken,
            { responseType: 'text' }
        );
    }

}