import { Injectable } from '@angular/core';
import { Effect, Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap, switchMap, mergeMap, catchError, merge } from 'rxjs/operators';

import * as UserActions from '../store/user.actions';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { User } from '../../../shared/models/user.model';
import Swal from 'sweetalert2';
import { UserService } from '../../../shared/services/user.service';
import { Store } from '@ngrx/store';

import * as fromApp from '../../../store/app.reducers';
import * as wishlistActions from '../../backoffice/wishlist/store/wishlist.actions';
import { CoreService } from 'src/app/shared/services/core.service';

@Injectable()
export class UserEffects {

    constructor(
        private coreService: CoreService,
        private actions$: Actions,
        private cookieService: CookieService,
        private userService: UserService,
        private router: Router,
        private store: Store<fromApp.AppState>) { }

    // @Effect()
    // tryLogin = this.actions$.pipe(
    //     ofType(UserActions.TRY_LOGIN),
    //     map((action: UserActions.TryLogin) => {
    //         return action.payload;
    //     }),
    //     switchMap((loginData: { username: string, password: string, rememberMe: boolean }) => {
    //         return this.userService.TryLogin(loginData)
    //             .pipe(
    //                 map((user: User) => {
    //                     let cookieExpire = null;

    //                     if (loginData.rememberMe) {
    //                         cookieExpire = new Date(new Date().setDate(new Date().getDate() + 365));
    //                     }

    //                     this.cookieService.set('userToken', user.token, cookieExpire);

    //                     return new UserActions.Login(user);
    //                 }),
    //                 catchError(() => {
    //                     return [new UserActions.LoginError()];
    //                 })
    //             );
    //     })
    // );

    tryLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.tryLogin),
            map((action) => {
                this.coreService.AppIsLoading(true);
                return action;
            }),
            switchMap((loginData: { username: string, password: string, rememberMe: boolean }) => {
                return this.userService.TryLogin(loginData)
                    .pipe(
                        map((user: User) => {
                            console.log(1);
                            let cookieExpire = null;

                            if (loginData.rememberMe) {
                                cookieExpire = new Date(new Date().setDate(new Date().getDate() + 365));
                            }

                            this.cookieService.set('userToken', user.token, cookieExpire);

                            this.coreService.AppIsLoading(false);

                            return UserActions.login({ user });
                        }),
                        catchError(() => {
                            this.coreService.AppIsLoading(false);
                            return [UserActions.loginError()];
                        })
                    );
            })
        )
    );

    // @Effect()
    // Login = this.actions$.pipe(
    //     ofType(UserActions.LOGIN),
    //     map((action: UserActions.Login) => {
    //         return action.payload;
    //     }),
    //     mergeMap((user: User) => {
    //         this.router.navigate(['/my-wishlists']);

    //         return [
    //             new UserActions.InitializeUser(user)
    //         ];
    //     })
    // );

    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.login),
            map((payload) => {
                this.store.dispatch(UserActions.initializeUser(payload));
                this.userService.UserToken = payload.user.token
            }),
            tap(() => {
                this.router.navigate(['/my-wishlists']);

                this.store.dispatch(wishlistActions.fetchWishlist())
            })
        ),
        { dispatch: false }
    );

    // @Effect()
    // FetchUser = this.actions$.pipe(
    //     ofType(UserActions.FETCH_USER),
    //     switchMap(() => {
    //         return this.userService.getUser().pipe(
    //             map((user: User) => {
    //                 return new UserActions.InitializeUser(user);
    //             })
    //         )
    //     })
    // )

    FetchUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.fetchUser),
            switchMap(() => {
                return this.userService.getUser().pipe(
                    map((user: User) => UserActions.initializeUser({ user })),
                    // tap(() => {
                    //     this.store.dispatch(wishlistActions.fetchWishlist());
                    // })
                )
            })
        )
    );

    // @Effect({ dispatch: false })
    // LoginError = this.actions$.pipe(
    //     ofType(UserActions.LOGIN_ERROR),
    //     tap(() => {
    //         Swal.fire('Fejl', 'Forkert brugernavn eller adgangskode!', 'error');
    //     })
    // );

    LoginError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.loginError),
            tap(() => {
                Swal.fire({
                    title: 'Fejl',
                    text: 'Forkert brugernavn eller adgangskode!',
                    icon: 'error'
                });
            })
        ),
        { dispatch: false }
    );

    // @Effect(({ dispatch: false }))
    // authLogout = this.actions$.pipe(
    //     ofType(UserActions.LOGOUT),
    //     tap(() => {
    //         this.userService.UserToken = 'undefined';
    //         this.cookieService.delete('userToken');
    //     })
    // );

    authLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserActions.logout),
            tap(() => {
                this.userService.UserToken = 'undefined';
                this.userService.userIsLoggedIn = false;
                this.cookieService.delete('userToken', '/');
            }),
            map(() => {
                this.store.dispatch(wishlistActions.setWishlists({ wishlists: null }))
            })
        ),
        { dispatch: false }
    );
}