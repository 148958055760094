import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import * as fromApp from '../store/app.reducers';
import * as fromUser from './../modules/user/store/user.reducers';
import * as userActions from './../modules/user/store/user.actions';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {
  isAuthenticated: Observable<fromUser.State>;
  isCollapsed: boolean = true;
  
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router) { }

  ngOnInit() {
    this.isAuthenticated = this.store.select('auth');

    this.router.events
    .pipe(filter(e => e instanceof(NavigationEnd)))
    .subscribe(() => {
      // On change, collapse nav.
      this.isCollapsed = true;

      // #region Animate route change.
      // let pageContent = document.body.querySelector('.page-content');
      // if (pageContent) {
      //   pageContent.classList.remove('transition-done');
      //   setTimeout(() => {
      //     pageContent.classList.add('transition-done');
      //   }, 10);
      // }
      // #endregion
    });
  }

  onLogout () {
    this.store.dispatch(userActions.logout());
    this.isCollapsed = true;
    this.router.navigateByUrl('/');
    Swal.fire({
      title: 'Log ud',
      text: 'Du er nu logget ud.',
      icon: 'success'
    });
  }

}
