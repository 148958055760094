import * as CoreActions from './core.actions';

export interface State {
    cookiesAccepted: boolean
}

const initialState: State = {
    cookiesAccepted: false
}

export function coreReducer (state = initialState, action: CoreActions.CoreActions) {
    switch(action.type) {
        case(CoreActions.COOKIES_ACCEPTED):
            return {
                ...state,
                cookiesAccepted: true
            }
        default:
            return state;
    }
}