<footer class="pt-3 pt-md-3 border-top bg-white">
    <div class="container">
        <div class="row">
            <div class="col-6 ">
                <h5>Nyttige links</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="text-muted" routerLink="/about">Om Wisher</a></li>
                    <li><a class="text-muted" routerLink="/about/contact">Kontakt Wisher</a></li>
                    <li><a class="text-muted" routerLink="/profile">Profil indstillinger</a></li>
                </ul>
            </div>
            <div class="col-6 ">
                <h5>Om Wisher</h5>
                <ul class="list-unstyled text-small">
                    <li><a class="text-muted" routerLink="/about/terms-and-conditions">Vilkår for brug</a></li>
                    <li><a class="text-muted" routerLink="/about/privacy-policy">Persondatapolitik</a></li>
                    <li><a class="text-muted" routerLink="/about/cookie-policy">Cookie- og Privatlivspolitik</a></li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<footer class="bg-primary">
    <ng-template [ngIf]="(appState$ | async).cookiesAccepted == 11">
        <div class="cookie-alert">
            <div class="cookie-alert-content">
                <div class="cookie-alert-content-grid">
                    <div>
                        <p class="cookie-alert-content-grid-text">Vi gør brug af cookies. Du accepterer vores <a routerLink="/cookie-policy" class="cookie-alert-content-grid-text-link">cookie-politik</a>, ved brug af siden.</p>
                    </div>
                    <div>
                        <button (click)="onAcceptCookies()" class="cookie-alert-content-grid-btn confirm">Accepter</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <script id="CookieDeclaration" src="https://consent.cookiebot.com/d97f9857-e301-47c9-8239-99ce51a3322c/cd.js" type="text/javascript" async></script>
    <div class="footer-copyright text-muted text-center py-3 border-top">
        Copyright &copy; {{ currentDate | date: 'yyyy' }} - Wisher.dk
    </div>
</footer>