<div class="landingpage__header d-flex align-items-center">
  <div class="landingpage__header-bg bg-async">
  </div>
  <div class="container text-white pt-3 pb-3">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-8 col-sm-10 offset-xl-3 offset-lg-3 offset-md-2 offset-sm-1 text-center">
        <h2 class="d-block mb-1">
          <span class="landingpage__header-title-subtitle text-secondary">Sig hej til Wisher</span>
          <span class="landingpage__header-title-heading text-uppercase">Din nye ønskeliste</span>
        </h2>
        <hr class="bg-secondary w-75" />
        <p class="text-secondary">Wisher gør det nemt, at holde styr på alle dine ønsker. Samtidig er det for dine gæster nemmere end nogensinde før, at koordinere, hvem der giver hvad.</p>
      </div>
    </div>
  </div>
</div>
<div class="bg-secondary arrow-up">
  <div class="container pt-4 pb-4">
    <a routerLink="/my-wishlists" class="btn btn-gold btn-block text-uppercase text-white"><i class="fas fa-gift mr-2"></i>Opret ønskeliste</a>
  </div>
</div>
<div class="container pt-3 pb-3">
  <h4>Hvad er Wisher?</h4>
  <p class="text-muted">Wisher er en online ønskeliste, der gør det nemt for dig, at samle alle dine ønsker ét sted.</p>
  <p class="text-muted">Når du først har oprettet din ønskeliste, er det nemt at dele den med venner og familie. Og dine gæster kan endda selv kordinere hvem, der giver hvad i gave, med Wishers indbyggede gave-reserverings-funktion.</p>
</div>