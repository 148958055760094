import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable()
export class SEOService {
    constructor(
        private title: Title,
        private meta: Meta
    ) { }


    UpdateTitle(title: string) {
        this.title.setTitle((title ? title : 'Gratis online ønskeliste'));
    }

    UpdateOgUrl(url: string) {
        this.meta.updateTag({ name: 'og:url', content: url })
    }

    UpdateDescription(desc: string) {
        this.meta.updateTag({ name: 'description', content: desc })
    }

    UpdateRobots(index: 'index' | 'noindex', follow: 'follow' | 'nofollow' ) {
        this.meta.updateTag({ name: 'robots', content: index + follow });
    }
}