import { Injectable, OnInit, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';

import { UserService } from './user.service';
import { Wish } from '../models/wish.model';

import * as fromApp from '../../store/app.reducers';
import { Store } from '@ngrx/store';
import { Wishlist } from '../models/wishlist.model';
import { Currency } from '../models/currency.model';
import { ScrapedWish } from '../models/scraped-wish.model';

@Injectable()
export class WishService implements OnDestroy {
    private wishlists$;
    private wishlists: Wishlist[];

    constructor(
        private httpClient: HttpClient,
        private store: Store<fromApp.AppState>,
        private userService: UserService
    ) { 
        // Fetch wish from NgRx store.
        this.wishlists$ = this.store.select(x => x.wishlists).subscribe(
            (wishlists) => {
                this.wishlists = wishlists;
            }
        );
    }

    ngOnDestroy() {
        // Unsubscribe from wishlists$.
        if (this.wishlists$ && !this.wishlists$.closed) {
            this.wishlists$.unsubscribe();
        }
    }

    GetWish(wishToken: string): Observable<Wish> {
        // #region Try get wish from NgRx store.
        if (this.wishlists) {
            let wishlist: Wishlist = this.wishlists.find(wishlist => wishlist.wishes ? wishlist.wishes.some(wish => wish.token == wishToken) : null);
            if (wishlist) {
                let wish: Wish = wishlist.wishes.find(wish => wish.token == wishToken);
    
                if (wish) {
                    return of(wish);
                }
            }
        }
        //#endregion

        // Fallback if NgRx doesn't contain the wish.
        return this.httpClient.get<Wish>(
            environment.API_URL + '/wishlist/wish/' + wishToken
        );
    }

    AddWish(wish: Wish): Observable<Wish> {
        return this.httpClient.post<Wish>(
          environment.API_URL + '/wishlist/wish/',
          wish
        )
    }

    UpdateWish(wish: Wish): Observable<Wish> {
        return this.httpClient.put<Wish>(
            environment.API_URL + '/wishlist/wish/' + this.userService.UserToken,
            wish
          )
    }

    DeleteWish(wishToken: string) {
        return this.httpClient.delete(
            environment.API_URL + '/wishlist/wish/' + wishToken + '/' + this.userService.UserToken,
            { responseType: 'text' }
        );
    }

    GetCurrencyTypes() {
        return this.httpClient.get<Currency[]>(
            environment.API_URL + '/shared/currency-types'
        );
    }

    Scrape(url: string) {
        return this.httpClient.get<ScrapedWish>(
            environment.API_URL + '/wish/scrape-uri',
            {
                params: {
                    wishUri: url
                }
            }
        )
    }
}
