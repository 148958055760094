import { Injectable, ɵɵresolveBody } from '@angular/core';

@Injectable()
export class CoreService {

  constructor() { }

  AppIsInitializing (initializing: boolean): void {
    if (initializing) {
      document.body.querySelector('.app-loading-overlay').classList.add('initializing');
    } else {
      document.body.querySelector('.app-loading-overlay').classList.remove('initializing');
    }
  }
  
  AppIsLoading (loading: boolean): void {
    if (loading) {
      document.body.querySelector('.app-loading-overlay').classList.add('loading');
    } else {
      document.body.querySelector('.app-loading-overlay').classList.remove('loading');
    }
  }

}
