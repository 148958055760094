import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Add API header if request is going to the Wisher API.
        if (req.url.startsWith(environment.API_URL) || req.url.startsWith('https://localhost:44343')) {

            var apiHeaders = new HttpHeaders({
                'Authorization': 'Basic QVBJOldpc2hlcjEyMw==',
            });

            // Append all pre-set headers from original request.
            req.headers.keys().forEach(key => {
                apiHeaders = apiHeaders.set(key, req.headers.get(key));
            });

            // Delete Content-Type from headers, if value is 'multipart/form-data'.
            // REASON: Angulars HttpClient might damage the request, if the 'Content-Type'
            //         is set to 'multipart/form-data'. 
            if (req.headers.get('Content-Type') == 'multipart/form-data' || req.body instanceof FormData) {
                apiHeaders = apiHeaders.delete('Content-Type');
            } else {
                // If 'Content-Type' is not set, set 'application/json' as default.
                apiHeaders = apiHeaders.append('Content-Type', 'application/json');
            }

            const modifiedReq = req.clone({ headers: apiHeaders })

            return next.handle(modifiedReq);
        }
        return next.handle(req);
    }
}