import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { FileManagerFile } from '../models/filemanagerfile.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class FileManagerService {

    constructor(
        private httpClient: HttpClient
    ) { }

    UploadFile(file, fileDisplayName: string = '', path: string = '/') {
        // #region Check if file is formData.
        let formData = new FormData();

        if (!(file instanceof FormData)) {
            formData.append('file', file);
        } else {
            formData = file;
        }
        // #endregion
        
        // #region Check if fileDisplayName is set.
        let originalFile: File = <File>formData.get('file');
        if (fileDisplayName == '') {
            fileDisplayName = originalFile.name;
        }
        // #endregion

        return this.httpClient.post<FileManagerFile>(
            environment.API_URL + '/filemanager/upload',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                params: {
                    path: encodeURIComponent(path),
                    fileDisplayName: fileDisplayName
                }
            }
        );
    }

    DeleteFile(fileGuid: string) {
        return this.httpClient.delete(
            environment.API_URL + '/filemanager/delete/' + fileGuid,
            { responseType: 'text' }
        );
    }

    CloneFile(fileGuid: string) {
        return this.httpClient.post<FileManagerFile>(
            environment.API_URL + '/filemanager/clone/' + fileGuid,
            null
        );
    }
}