import { createReducer, on } from '@ngrx/store';

import { tryLogin, signup, initializeUser, logout, login, loginError, fetchUser } from './user.actions';
import { User } from '../../../shared/models/user.model';

export interface State extends User { }

export const initialState: State = null;

const _userReducer = createReducer(initialState,
    on(logout, (state) => null),
    on(initializeUser, (state, { user }) => user),
);

export function userReducer(state, action) {
    return _userReducer(state, action);
}

// export function authReducer(state = initialState, action: UserActions.UserActions) {
//     switch (action.type) {
//         case (UserActions.SIGNUP):
//             return {
//                 ...state,
//                 authenticated: true
//             }
//         case (UserActions.LOGOUT):
//             return {
//                 ...state,
//                 token: null,
//                 authenticated: false,
//                 user: null
//             }
//         case (UserActions.INITIALIZE_USER):
//             return {
//                 ...state,
//                 user: action.payload,
//                 authenticated: true,
//                 token: action.payload.token
//             }
//         case (UserActions.SET_TOKEN):
//             return {
//                 ...state,
//                 token: action.payload
//             }
//         default:
//             return state;
//     }
// }