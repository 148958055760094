import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class SignupRequiredGuard implements CanActivate {

    constructor(
        private router: Router,
        private cookieService: CookieService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
        if (this.cookieService.check('userToken')) {
            return true;
        } else {
            this.router.navigate(['/sign-up']);
            return false;
        }
    }
}